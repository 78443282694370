import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { useNewCommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useCommittableEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import useEntityValue from '../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { useComputed } from 'mobx-react-lite';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import { CommitBuilder } from '../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { updateRelationship } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { computed } from 'mobx';
import useRelatedEntity from '../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useProductLineDescription, { ProductLineDescriptionEditorInterface } from './useProductLineDescription';
import { determinePriceForProductLine } from '../Api/determinePriceForProductLine';

export interface ProductLineEditorInterface
{
    uuid: string,
    description: ProductLineDescriptionEditorInterface;
    isStrikethrough: boolean,
    onInvoice: () => void;
    productLine: Entity;
    commitContext: CommitContext;
    setProduct: (newProduct: Entity | undefined) => Promise<void>;
    isProductAndQuantityAndPriceDisabled: boolean,
    isSubscriptionLine: boolean,
    isPriceReadOnly: boolean,
    isVatReadOnly: boolean,
    stockLevel?: number;
    isParentActivityAnInvoiceOrSalesOrderOrProject: boolean,
    onBlurQuantity: () => Promise<void>;
    setQuantityTouched: () => void;
    determinePrice: () => Promise<any>;
}

export default function useProductLine(
    productLine: Entity,
    context?: CommitContext,
    onSelectProductLine?: (entity: Entity, doInvoice: boolean) => void,
    isSelectedProductLine?: boolean,
    isStrikethroughed?: (line: Entity) => boolean,
    loading?: boolean,
    currency?: string,
    disabled?: boolean,
    autoCommit?: boolean
) : ProductLineEditorInterface
{
    const types = useTypes();
    const commitContext = useNewCommitContext(context)
    const committableProductLine = useCommittableEntity(productLine, commitContext);
    const productLineDescription = useProductLineDescription(committableProductLine, commitContext);

    const onInvoice =
        useCallback(
            () =>
                onSelectProductLine(
                    committableProductLine,
                    !isSelectedProductLine),
            [
                onSelectProductLine,
                isSelectedProductLine,
                committableProductLine
            ]
        );

    const isStrikethrough =
        useComputed(
            () =>
            {
                if (isStrikethroughed)
                {
                    return isStrikethroughed(committableProductLine);
                }
                else
                {
                    return false;
                }
            },
            [
                isStrikethroughed,
                committableProductLine
            ]);

    const savePriceStateContainer =
        useMemo(
            () => ({ state: uuid() }),
            []
        );

    const activity =
        useRelatedEntity(
            productLine,
            types.Activity.RelationshipDefinition.ProductLines,
            true,
            commitContext
        );

    const now =
        useMemo(
            () => new Date(),
            []
        );

    const determinePriceAndSave =
        useCallback(
            async () =>
            {
                // If data is still loading, then it might be that the price trigger is triggered due to a change of data because of the loading
                // not because the user changed the product line
                if (loading)
                {
                    return Promise.resolve();
                }

                const builder = new CommitBuilder(commitContext);
                const state = uuid();
                savePriceStateContainer.state = state;

                const product =
                    committableProductLine.getRelatedEntityByDefinition(
                        false,
                        types.ProductLine.RelationshipDefinition.Product,
                        commitContext
                    );

                const vatGroup =
                    committableProductLine.getRelatedEntityByDefinition(
                        false,
                        types.ProductLine.RelationshipDefinition.VatGroup,
                        commitContext
                    );

                if (!product || !vatGroup)
                {
                    return Promise.resolve();
                }

                const priceSetByUser =
                    (
                        committableProductLine.hasValueForField(types.ProductLine.Field.Price, commitContext) ||
                        committableProductLine.hasValueForField(types.ProductLine.Field.PriceInCurrency, commitContext)
                    ) &&
                    (committableProductLine.getObjectValueByField(types.ProductLine.Field.IsPriceManuallyChanged, commitContext) ?? false);

                if (priceSetByUser)
                {
                    if (savePriceStateContainer.state === state
                        && autoCommit !== false)
                    {
                        await builder.commit();
                        return Promise.resolve();
                    }
                }


                const newPrice =
                    await determinePriceForProductLine(
                        committableProductLine,
                        product,
                        activity,
                        commitContext,
                        now
                    );

                if (currency)
                {
                    builder.setObjectValueInEntity(
                        committableProductLine,
                        types.ProductLine.Field.PriceInCurrency,
                        newPrice ?? 0
                    );
                }
                else
                {
                    builder.setObjectValueInEntity(
                        committableProductLine,
                        types.ProductLine.Field.Price,
                        newPrice ?? 0
                    );
                }

                builder.setObjectValueInEntity(
                    committableProductLine,
                    types.ProductLine.Field.IsPriceManuallyChanged,
                    false
                );

                if (savePriceStateContainer.state === state && autoCommit !== false)
                {
                    await builder.commit();
                }

                return Promise.resolve();

            },
            [
                loading,
                savePriceStateContainer,
                committableProductLine,
                types,
                autoCommit,
                commitContext,
                currency,
                activity,
                now
            ]);

    // When the product changes, then also update the description, purchase price, price, the VAT group and
    // the repeat interval
    const setProduct =
        useCallback(
            async (newProduct: Entity | undefined) =>
            {
                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.Description,
                    newProduct?.name,
                    commitContext
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.LocalizedDescription,
                    newProduct?.getObjectValueByField(types.Product.Field.LocalizedName, commitContext),
                    commitContext
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.PurchasePrice,
                    newProduct?.getObjectValueByField(types.Product.Field.PurchasePrice, commitContext),
                    commitContext
                );

                const vatGroup =
                    newProduct?.getRelatedEntityByDefinition(
                        false,
                        types.Product.RelationshipDefinition.VatGroup,
                        commitContext
                    );

                updateRelationship(
                    committableProductLine,
                    false,
                    types.ProductLine.RelationshipDefinition.VatGroup,
                    vatGroup,
                    commitContext
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.RepeatInterval,
                    newProduct?.getObjectValueByField(types.Product.Field.RepeatInterval, commitContext),
                    commitContext
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.ExtraDescription, // should this be just Description?
                    newProduct?.getObjectValueByField(types.Product.Field.Description, commitContext),
                    commitContext
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.Currency,
                    currency,
                    commitContext
                );

                const hasExtraDescription =
                    committableProductLine.hasValueForField(
                        types.ProductLine.Field.ExtraDescription,
                        commitContext
                    );

                await productLineDescription.setExtraDescriptionVisible(
                    hasExtraDescription,
                    false
                );

                setValueByFieldInEntity(
                    committableProductLine,
                    types.ProductLine.Field.IsPriceManuallyChanged,
                    false,
                    commitContext
                );

                await determinePriceAndSave();

            },
            [
                committableProductLine,
                types,
                determinePriceAndSave,
                productLineDescription,
                commitContext,
                currency,
            ]
        );

    // Avoid onBlurQuantity being recreated because that triggers a recreation of the entire quantity value editor
    // Losing its focus on touch devices
    const isQuantityTouched = useRef(false);
    const setQuantityTouched =
        useCallback(
            () =>
                isQuantityTouched.current = true,
            []
        );
    const setQuantityUntouched =
        useCallback(
            () =>
                isQuantityTouched.current = false,
            []
        );
    const onBlurQuantity =
        useCallback(
            async () =>
            {
                if (isQuantityTouched.current)
                {
                    await determinePriceAndSave();
                    setQuantityUntouched();
                }
            },
            [
                determinePriceAndSave,
                setQuantityUntouched,
            ]
        );

    const isVatReadOnly =
        useComputed(
            () =>
                committableProductLine.hasRelationshipsByDefinition(
                    false,
                    types.ProductLine.RelationshipDefinition.VatGroup,
                    commitContext
                )
                && committableProductLine.getRelatedEntitiesByDefinition(
                    false,
                    types.ProductLine.RelationshipDefinition.Product,
                    commitContext
                )
                    .some(
                        product =>
                            !product.getObjectValueByField(types.Product.Field.IsVatEditable, commitContext)
                    ),
            [
                committableProductLine,
                types,
                commitContext,
            ]);

    const isPriceReadOnly =
        useComputed(
            () =>
                committableProductLine.getRelatedEntitiesByDefinition(
                    false,
                    types.ProductLine.RelationshipDefinition.Product,
                    commitContext
                )
                    .some(
                        product =>
                            !product.getObjectValueByField(
                                types.Product.Field.IsPriceEditable,
                                commitContext
                            )
                    ),
            [
                committableProductLine,
                types,
                commitContext,
            ]);

    const stockLevel =
        useComputed(
            () =>
                committableProductLine?.getRelatedEntityByDefinition(
                    false,
                    types.ProductLine.RelationshipDefinition.Product,
                    commitContext
                )?.getObjectValueByField(types.Product.Field.StockLevel, commitContext),
            [
                committableProductLine,
                types,
                commitContext,
            ]);


    // When the activity currency changes and this is a new productLine, then update the currency
    useEffect(
        () =>
            computed(
                () =>
                    activity.getObjectValueByField(types.Activity.Field.Currency, commitContext)
            )
                .observe(
                    change =>
                    {
                        if (committableProductLine.isNew())
                        {
                            setValueByFieldInEntity(committableProductLine, types.ProductLine.Field.Currency, change.newValue, commitContext);
                        }
                    }
                ),
        [
            committableProductLine,
            activity,
            types,
            commitContext,
        ]);

    const isSubscriptionLine =
        useMemo(
            () =>
                activity?.entityType.isA(types.Activity.Subscription.Type),
            [
                activity,
                types
            ]);

    const isSubscriptionActive =
        useMemo(
            () =>
            {
                if (activity)
                {
                    const phase =
                        activity.getRelatedEntityByDefinition(
                            false,
                            types.Activity.Subscription.RelationshipDefinition.Phase,
                            commitContext
                        );

                    if (phase)
                    {
                        return phase.getObjectValueByField(types.Datastore.Field.Code, commitContext) === types.Activity.Subscription.Phase.Activated;
                    }
                }

                return false;
            },
            [
                activity,
                types,
                commitContext,
            ]);

    const billedUntil =
        useEntityValue(
            committableProductLine,
            types.ProductLine.Field.BilledUntil,
            undefined,
            commitContext
        );
    const isBilled =
        useMemo(
            () =>
                billedUntil !== undefined,
            [
                billedUntil
            ]);

    const isProductAndQuantityAndPriceDisabled =
        useMemo(
            () =>
            {
                if (disabled)
                {
                    return true;
                }
                else if (isSubscriptionActive)
                {
                    return isBilled;
                }
                else
                {
                    return false;
                }
            },
            [
                disabled,
                isSubscriptionActive,
                isBilled
            ]);

    const isParentActivityAnInvoiceOrSalesOrderOrProject =
        useComputed(
            () =>
                committableProductLine.getRelatedEntityByDefinition(
                    true,
                    types.Activity.RelationshipDefinition.ProductLines,
                    commitContext)?.entityType.isEither(
                    types.Activity.Invoice.Type,
                    types.Activity.SalesOrder.Type,
                    types.Activity.Project.Type),
            [
                committableProductLine,
                types,
                commitContext,
            ]);

    return useMemo(
        () =>
            ({
                uuid: committableProductLine.uuid,
                description: productLineDescription,
                productLine: committableProductLine,
                isStrikethrough: isStrikethrough,
                onInvoice: onInvoice,
                commitContext: commitContext,
                setProduct: setProduct,
                isProductAndQuantityAndPriceDisabled: isProductAndQuantityAndPriceDisabled,
                isParentActivityAnInvoiceOrSalesOrderOrProject: isParentActivityAnInvoiceOrSalesOrderOrProject,
                isSubscriptionLine: isSubscriptionLine,
                isPriceReadOnly: isPriceReadOnly,
                isVatReadOnly: isVatReadOnly,
                stockLevel: stockLevel,
                onBlurQuantity: onBlurQuantity,
                setQuantityTouched: setQuantityTouched,
                determinePrice: determinePriceAndSave,
            }),
        [
            productLineDescription,
            committableProductLine,
            isStrikethrough,
            onInvoice,
            commitContext,
            setProduct,
            isProductAndQuantityAndPriceDisabled,
            isParentActivityAnInvoiceOrSalesOrderOrProject,
            isSubscriptionLine,
            isPriceReadOnly,
            isVatReadOnly,
            stockLevel,
            onBlurQuantity,
            setQuantityTouched,
            determinePriceAndSave,
        ]
    );
}